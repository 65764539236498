import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledServiceDiv = styled.div`

 width: 30%;
text-align: center;

align-items:center;
justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:4%;

  @media screen and (max-width: 1295px) {
width:100%;
   }


`;

const DivBlock = styled.div`

`

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
text-align: center;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:100%;
position: relative; 

  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }


`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
width:100%;
color:rgb(68, 68, 68);
margin-top:0.8%;
position: relative; 

  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:100%;
color:rgb(68, 68, 68);
margin-top:1.3%;
position: relative; 
height:201px;

  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }
`;

const Bold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
margin-bottom: 1.0em;
color:rgb(68, 68, 68);
font-weight: bold;
width:100%;
margin-top:1.3%;

  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }

`

interface ServiceDivProps {
  className?: string
}

const ServiceDiv: React.FC<ServiceDivProps> = ({ }) => (
  <StyledServiceDiv>
<StyledP>Vilhelm7 <br/> Additional Service</StyledP>
    <P><FormattedMessage id="specials_text_8" /> </P>
    <Bold><FormattedMessage id="specials_text_9" /> </Bold>



  </StyledServiceDiv>

)

export default ServiceDiv
