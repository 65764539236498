import * as React from 'react'
import styled from '@emotion/styled'

import IndexLayout from '../layouts'
import BikesDiv from "../components/specials/BikesDiv";
import LaundryDiv from "../components/specials/LaundryDiv";
import ServiceDiv from "../components/specials/ServiceDiv";
import HeaderDesktop from "../components/HeaderDesktop";
import Helmet from "react-helmet";

const Div = styled.div`
display:flex;
width:80%;
margin-left:auto;
margin-right:auto;
justify-content:space-between;

 @media screen and (max-width: 1295px) {
display:block;
width:80%;


   }
`

const Specials = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Extras'}
      meta={[
        { name: 'description', content: 'Vilhelm7 Bikes. Laundry service. Exrta room service.' },
        { name: 'keywords', content: 'vilhelm, wilhelm, 7, aesop, bike, spa, coworking, Berlin, kreuzberg'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop></HeaderDesktop>
    <Div>
    <BikesDiv></BikesDiv>
    <LaundryDiv></LaundryDiv>
    <ServiceDiv></ServiceDiv>
    </Div>
  </IndexLayout>
)

export default Specials
