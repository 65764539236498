import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledBikesDiv = styled.div`

  width: 30%;
height:auto;

align-items:center;
text-align:center !important;
justify-content: center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:4%;

  @media screen and (max-width: 1295px) {
width:100%;
   }

`;

const DivBlock = styled.div`

`

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
text-align:center;

width:100%;
position: relative; 
  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
margin-left:auto;
margin-right:auto;
width:80%;
   }

`;



const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:100%;
color:rgb(68, 68, 68);
margin-top:1.3%;
position: relative; 
text-align:center;
height:201px;


  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }
`;

const Bold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
margin-bottom: 1.0em;
color:rgb(68, 68, 68);
font-weight: bold;
width:100%;
margin-top:1.3%;
text-align:center;


  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
width:100%;
margin-right:auto;
margin-left:auto;
   }


`

interface BikesDivProps {
  className?: string
}

const BikesDiv: React.FC<BikesDivProps> = ({ }) => (
  <StyledBikesDiv>
<StyledP>Vilhelm7 <br/> Bikes</StyledP>
    <P><FormattedMessage id="specials_text_4" /> <br/> <FormattedMessage id="specials_text_4_1" /></P>
    <Bold> <FormattedMessage id="specials_text_5" /></Bold>



  </StyledBikesDiv>

)

export default BikesDiv
